<template>
  <div class="page-content">
    <page-breadcrumb title="List All Main Topics" class="mb-2" />

    <data-table-ssr id="item_list" ref="item_list"
      :create-row-fn="createItem" :create_schema="create_schema" :item_default_data="{
        name: { type: 'translatable-text', translations: [] }
      }"
      :get-list-fn="getList" :columns="item_fields"
      :update_schema="update_schema" :edit-row-fn="updateItem"
      :delete-row-fn="deleteItem"
      :create_button_text="'New Main Topic'"
      @name_on_click="topicNameOnClick"
    />
  </div>
</template>

<script>
import { removeEmpty } from '@core/utils/index'
import service from '../service'
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Topic Name', field: 'name', input_type: 'ui-component' },
    ]
  }
];
const update_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Topic Name', field: 'name', input_type: 'ui-component' },
    ]
  }
];
const item_fields= [
  { label: 'Main Topics', field: 'name', display_type: 'translatable-text', click_event: true },
]

export default {
  data(){
    return{
      create_schema, update_schema,
      total: 0,
      item_fields
    }
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create(data);
      return new_item;
    },
    async getList({ limit, page, query }) {
      query = removeEmpty(query);
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total }
    },
    async updateItem(row) {
      await service.update({ data: JSON.stringify(row) });
      // this.$refs.item_list.getList();
    },
    async toEditPage({ _id }) {
      this.$router.push({ name: 'learning_topic-detail', params: { id: _id } });
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
    },
    topicNameOnClick(data){
      this.$router.push({ name: 'lesson-filterd-by-topic', params: {id: data._id} });
    }
  }
}
</script>
